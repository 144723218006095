import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'

const endpoint = 'customer_area'

export const getAll = createAsyncThunk(`${endpoint}/events/all`, async params => {
  const response = await axios.get(`${endpoint}/events`, { params })
  return {
    data: response.data.data,
    total: response.data.meta.total,
    last_page: response.data.meta.last_page,
    current_page: response.data.meta.current_page,
    params
  }
})

export const get = createAsyncThunk(`${endpoint}/get`, async id => {
  const response = await axios.get(`${endpoint}/event/${id}`, { id })
  return response.data.data
})

export const divulgacao = createAsyncThunk(`/divulgacao`, async (item) => {
  item._method = 'POST';
  const response = await axios.post(`/divulgacao`, item)
  return response.data
})

export const update = createAsyncThunk(`${endpoint}/update`, async (item) => {
  await axios.put(`${endpoint}/event/${item.id}`, item)
  return item
})

export const updateImgInvite = createAsyncThunk(`${endpoint}/updateImg`, async (item) => {
  item._method = 'PUT'
  await axios.postForm(`${endpoint}/event/${item.id}`, item)
  return item
})

export const configMensagem = createAsyncThunk(`${endpoint}/updateImg`, async (item) => {
  item._method = 'PUT'
  await axios.postForm(`${endpoint}/event/${item.id}`, item)
  return item
})

export const addConvidado = createAsyncThunk(`${endpoint}/addConvidado`, async (item, { dispatch }) => {
  const response = await axios.post(`${endpoint}/divulgacao`, item)
  return response.data
})
export const updateConvidado = createAsyncThunk(`${endpoint}/updateConvidado`, async (item) => {
  await axios.put(`/divulgacao/${item.id}`, item)
  return item
})
export const removeConvidado = createAsyncThunk(`${endpoint}/removeConvidado`, async (id, { dispatch }) => {
  const response = await axios.delete(`/divulgacao/${id}`)
  return response.data
})

export const removeMultiplesParticipants = createAsyncThunk(`${endpoint}/removeMultiplesParticipants`, async ({ ids, uri_convite }) => {
  await axios.delete('/divulgacoes/delete-multiples', {
    data: {
      ids: ids,
      uri_convite: uri_convite
    }
  })
})

export const customer_area = createSlice({
  name: endpoint,
  initialState: {
    data: [],
    convidados: [],
    adultos: [],
    message: '',
    item: {},
    status: 'READY',
    total: 0,
    last_page: 1,
    current_page: 1,
    params: {},
    error: ''
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAll.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.total
        state.last_page = action.payload.last_page
        state.status = 'SUCCESS'
      })
      .addCase(getAll.pending, (state) => {
        state.loading = true
        state.data = []
        state.status = 'PENDING'
      })
      .addCase(getAll.rejected, (state, action) => {
        state.loading = false
        state.data = []
        state.error = action.payload || 'Não foi possível carregar os dados'
        state.status = 'ERROR'
      })
      .addCase(get.pending, (state, action) => {
        state.status = 'PENDING'
        state.selected = action.payload
      })
      .addCase(get.rejected, (state, action) => {
        state.status = 'ERROR'
        state.error = action.payload
      })
      .addCase(get.fulfilled, (state, action) => {
        state.status = 'SUCCESS'
        state.item = action.payload
        state.convidados = action.payload.convidados
        state.adultos = action.payload.adultos
      })
      .addCase(divulgacao.fulfilled, (state, action) => {
        state.status = 'SAVE_GUEST_SUCCESS'
        state.message = 'Convidado adicionado com sucesso'
      })
      .addCase(divulgacao.rejected, (state, action) => {
        state.status = 'SAVE_GUEST_ERROR'
        state.message = 'Não foi possível adicionar'
      })
      .addCase(update.fulfilled, (state, action) => {
        state.status = 'SAVE_SUCCESS'
        state.message = 'Atualizado com sucesso'
      })
      .addCase(updateImgInvite.fulfilled, (state, action) => {
        state.status = 'UPDATED_SUCCESS_IMG'
        state.message = 'Imagem atualizada com sucesso'
      })
      .addCase(updateImgInvite.rejected, (state, action) => {
        state.status = 'UPDATED_ERROR_IMG'
        state.message = 'Não foi possível atualizar'
      })
      .addCase(updateConvidado.fulfilled, (state, action) => {
        state.status = 'UPDATED_GUEST_SUCCESS'
        state.message = 'Convidado atualizado com sucesso'
      })
      .addCase(updateConvidado.rejected, (state, action) => {
        state.status = 'UPDATED_GUEST_ERROR'
        state.message = 'Não foi possível atualizar o convidado'
      })
      .addCase(addConvidado.fulfilled, (state, action) => {
        state.status = 'SAVE_SUCCESS'
        state.message = action.payload.message
      })
      .addCase(removeConvidado.fulfilled, (state, action) => {
        state.status = 'REMOVE_SUCCESS'
        state.message = action.payload.message
      })
      .addCase(removeConvidado.rejected, (state, action) => {
        state.status = 'REMOVE_ERROR'
      })
      .addCase(removeMultiplesParticipants.rejected, (state, action) => {
        state.status = 'REMOVE_PARTICIPANTS_ERROR'
      })
      .addCase(removeMultiplesParticipants.fulfilled, (state, action) => {
        state.status = 'REMOVE_PARTICIPANTS_SUCCESS'
      })
      .addCase(removeMultiplesParticipants.pending, (state, action) => {
        state.status = 'REMOVE_PARTICIPANTS_PENDING'
      })
      .addCase(update.rejected, (state, action) => {
        state.status = 'SAVE_ERROR'
        state.message = 'Não foi possível atualizar'
      })

  }
})

export default customer_area.reducer
