// ** Reducers Imports
import navbar from './navbar'
import layout from './layout'
import auth from './authentication'
import customer_area from '../views/customer_area/store'

const rootReducer = {
  auth,
  navbar,
  layout,
  customer_area
}

export default rootReducer
